export const PATH_NAME = Object.freeze({
  HOME: "/",
  SIGN_IN: "/sign_in",
  SIGN_UP: "/sign_up",
  FORGET_PW: "/forget_pw",
  FAQ: "/faq",
  LANDING: "/landing",
  MYLINKS: "/my_links",
  STATS: "/stats",
  PROFILE: "/profile",
  SUBSCRIPTIONPLANS: "/subscription_plans",
  ADD_NEW_PODCAST: "/addPodcast",
  CHOOSE_LINK: "/createLink",
  EXTERNAL_SIGN_UP: "/createUser/mics",
  USER_CONSOLE: "/podcasts",
  EPISODES: "/episodes",
  PODCAST_EPISODES: "/episodes/:id",
  SETTINGS: "/settings",
  REPORTS: "/reports"
});
