import axios from "axios";
import { requestHandler, responseErrorHandler } from "./interceptor";
// export const baseURL = "http://localhost:8080/";
export const baseURL = process.env.REACT_APP_BACKEND_URL;
const commonConfigs = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*"
  },
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  commonConfigs,
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
);

export { axiosInstance };
